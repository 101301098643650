
import { defineComponent, ref, watch } from 'vue';

import { Index } from '@functions/model/firestore';
import firebase from 'firebase';
import DocumentData = firebase.firestore.DocumentData;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import { adminService } from '@/services';
import { useAuth } from '@/store/useAuth';
import { useMeta } from '@/store/useMeta';
import DownloadMelatiCsv from '@/components/Admin/DownloadMelatiCsv.vue';

interface User {
  email: string;
  password: string;
}

export default defineComponent({
  components: { DownloadMelatiCsv },
  async setup() {
    useMeta({ title: 'Admin', noIndex: true });

    const authenticated = ref(false);
    const user = ref<User>({ email: '', password: '' });
    const error = ref(false);
    const indexes = ref<Index[]>([]);
    const pageSize = ref(20);
    const allFetched = ref(false);
    const cursor = ref<QueryDocumentSnapshot<DocumentData>>();

    const { initAuth, signInAdmin, signOut } = useAuth(auth => {
      auth.onAuthStateChanged(async user => {
        authenticated.value = !!user;

        if (user?.isAnonymous) {
          await signOut();
        }
      });
    });

    function formatDateString(dateString: string) {
      // @ts-ignore
      return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(dateString));
    }

    async function signIn() {
      error.value = !user.value.email || !user.value.password;

      if (!error.value) {
        try {
          const fbUser = await signInAdmin(user.value.email, user.value.password);
          if (fbUser) {
            authenticated.value = true;
          }
        } catch {
          error.value = true;
        }
      }
    }

    async function deleteMessage(id: string) {
      const deleteConfirmed = confirm(
        'Weet je zeker dat je deze melati wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.'
      );

      if (!deleteConfirmed) {
        return;
      }

      try {
        await adminService.deleteMessage(id);
        indexes.value.splice(
          indexes.value.findIndex(index => index.id === id),
          1
        );
      } catch (e) {
        console.error('Error deleting melati:', e);
        error.value = true;
      }
    }

    async function getMessageIndexes() {
      if (allFetched.value) {
        return;
      }

      const messageIndexes = await adminService.getMessageIndexes(pageSize.value, cursor);
      indexes.value = [...indexes.value, ...messageIndexes];

      if (messageIndexes.length < pageSize.value) {
        allFetched.value = true;
      }
    }

    watch(authenticated, async (val: boolean, oldVal: boolean) => {
      if (val && !oldVal) {
        user.value = { email: '', password: '' };
        await getMessageIndexes();
      } else {
        indexes.value = [];
        cursor.value = undefined;
        allFetched.value = false;
      }
    });

    await adminService.init();
    await initAuth();

    return {
      authenticated,
      user,
      error,
      indexes,
      allFetched,
      formatDateString,
      signIn,
      signOut,
      deleteMessage,
      getMessageIndexes
    };
  }
});
